import { Router, scrollBehavior } from '@/utils/router.js';
import { setHtmlMeta } from '@/utils/utils';
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_news" */ './home/home.vue'),
      meta: { desc: '首页' },
    },
    {
      path: '/list/:topic',
      name: 'List',
      component: () => import(/* webpackChunkName: "core_news" */ './home/home.vue'),
      meta: { desc: '列表页' },
    },
    {
      path: '/detail/:topic/:id',
      name: 'Detail',
      component: () => import(/* webpackChunkName: "core_news" */ './detail/detail.vue'),
      meta: { desc: '新闻详情页' },
    },
    {
      path: '/feature/:topic/:id',
      name: 'Feature',
      component: () => import(/* webpackChunkName: "core_news" */ './feature/feature.vue'),
      meta: { desc: '专题详情页' },
    },
    {
      //special subject
      path:'/specials/subject/:id',
      name:'SpecialSubject',
      component:()=> import(/* webpackChunkName: "core_news" */ './specialSubject/specialSubject.vue')
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "core_news" */ './search/search.vue'),
      meta: { desc: '搜索结果页' },
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_news" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_news" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  if( document.querySelectorAll('.format-data')){
    var awaitRemoveDom = document.querySelectorAll('.format-data');
    for(var i=0; i<awaitRemoveDom.length;i++){
      document.head.removeChild(awaitRemoveDom[i])
    }
    
  }
  if( document.querySelectorAll('.og-twitter')){
    var awaitRemoveDom = document.querySelectorAll('.og-twitter');
    for(var i=0; i<awaitRemoveDom.length;i++){
      document.head.removeChild(awaitRemoveDom[i])
    }
  }
  if (to.name == 'Home') {
    setHtmlMeta({
      title: '加拿大新闻 - 约克论坛 - 加拿大第一中文网',
      keywords: '加拿大新闻,多伦多新闻,华人新闻,yorkbbs news,toronto news,canada news',
      description:
        '华人新闻、加拿大新闻、多伦多新闻、社区活动，今日头条以及加拿大华人关注的各类生活资讯等，约克论坛新闻频道24小时不间断为您报道。 toronto news，yorkbbs.ca',
    });
  } else if (to.name == 'List') {
    const { topic } = to.params;

    if (topic == 'local') {
      setHtmlMeta({
        title: '多伦多新闻 - 加拿大新闻 - 约克论坛',
        keywords: '加拿大新闻,多伦多本地新闻,华人新闻,toronto news',
        description:
          '约克论坛本地新闻涵盖了加拿大新闻、多伦多本地新闻、海外华人新闻、社区新闻等内容，为华人和留学生提供实时中文新闻报道。 toronto news，yorkbbs.ca',
      });
    } else if (topic == 'world') {
      setHtmlMeta({
        title: '综合新闻 - 加拿大新闻 - 约克论坛',
        keywords: '国际新闻,社会新闻,时事新闻,娱乐新闻,国内新闻,海外华人,中文新闻',
        description: '约克论坛综合新闻包含各类社会新闻、国际新闻、中国新闻、时事新闻、娱乐新闻等综合报道。 toronto news，yorkbbs.ca',
      });
    } else if (topic == 'life') {
      setHtmlMeta({
        title: '生活资讯 - 加拿大新闻 - 约克论坛',
        keywords: '生活资讯,加拿大生活资讯,多伦多生活资讯,chinese in toronto life guide,多伦多华人生活指南',
        description:
          '为加拿大华人和留学生提供房产、汽车、美食、旅游、移民、留学、理财、商业、福利等相关生活指南服务。 toronto news，yorkbbs.ca',
      });
    } else if (topic == 'business') {
      setHtmlMeta({
        title: '社区工商 - 加拿大新闻 - 约克论坛',
        keywords: '加拿大社区,多伦多社区,华人社区,社区活动,加拿大商会,多伦多商会,工商动态',
        description:
          '社区工商新闻提供加拿大华人社区活动、多伦多社区新闻、义工服务、华人工商活动、多伦多商会动态等全方位信息。 toronto news，yorkbbs.ca',
      });
    } else if (topic == 'featured') {
      setHtmlMeta({
        title: '专题活动 - 加拿大新闻 - 约克论坛',
        keywords: '新闻,新闻专题,加拿大新闻专题,华人活动,约克论坛征稿,热点新闻,今日头条',
        description:
          '提供各类重大突发新闻、多伦多吃喝玩乐榜单、移民留学攻略、房屋汽车、教育投资、中文黄页等专题报道和活动跟踪。 toronto news，yorkbbs.ca',
      });
    } else if (topic == 'guide') {
      setHtmlMeta({
        title: '多伦多攻略 - 加拿大新闻 - 约克论坛',
        keywords: '枪牌，考驾照，',
        description:
          '多伦多生活的好帮手。 toronto news，yorkbbs.ca',
      });
    } else if (topic == 'events') {
      setHtmlMeta({
        title: '多伦多周末好去处/演唱会 - 活动预告 - 约克论坛',
        keywords: '周末亲子，演唱会，各种活动，电影',
        description:
          '周末亲子游，多伦多演唱会',
      });
    } else if (to.name == 'Search') {
      setHtmlMeta({
        title: '搜索 - 加拿大新闻 - 约克论坛',
        keyword: '',
        description: '',
      });
    } else if (to.name == 'WebSafe') {
      setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
    } else if (to.name == 'NotFound') {
      setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
    } else {
      setHtmlMeta({ title: '', keywords: '', description: '' });
    }
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
